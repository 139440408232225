const selectors = {
  scheduleData: (state) => state.schedule.scheduleData,
  firstPreferenceData: (state) => state.schedule.firstPreferenceData,
  secondPreferenceData: (state) => state.schedule.secondPreferenceData,
  lessonForReschedule: (state) => state.schedule.lessonForReschedule,
  fetchingScheduleStatus: (state) => state.schedule.fetchingScheduleStatus,
  lessonsIdForPayment: (state) => state.schedule.lessonsIdForPayment,
  pendingPrivateRequests: (state) => state.schedule.pendingPrivateRequests,
};

export { selectors };
