const status = {
  IDLE: 'idle',
  PENDING: 'pending',
  SUCCESS: 'success',
  FAIL: 'fail',
};

const thunkStatus = {
  PENDING: 'pending',
  FULFILLED: 'fulfilled',
  REJECTED: 'rejected',
};

const appDateFormat = {
  YMD: 'yyyy-MM-dd',
  DMY_DASH: 'dd-MM-yyyy',
  DMY: 'dd.MM.yyyy',
  DAY_MONTH_FULL: 'd MMMM',
  M_FULL_D_Y_FULL: 'MMMM dd, yyyy',
  M_FULL_D_SUFFIX_Y_FULL: 'MMMM do, yyyy',
  M: 'EEE',
  D_M_SHORT: 'dd MMM',
};

const appTimeFormat = {
  AMPM_12H: 'h:mm a',
  H_12: 'h:mm',
  H_24: 'HH:mm',
  H_24_SECONDS: 'HH:mm:ss',
};

export { status, thunkStatus, appDateFormat, appTimeFormat };
