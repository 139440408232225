export const APP_ROUTES = {
  auth: '/',
  notFound: '/not-found',
  payment: {
    payment: '/payment',
    paymentSuccess: '/payment/success',
  },
  media: {
    mediaMain: '/media',
    currentMedia: '/media/:mediaId',
  },
  schedule: {
    scheduleMain: '/schedule',
    rescheduleLesson: '/schedule/reschedule-lesson',
    privateLesson: '/schedule/private-lesson',
  },
  profile: {
    profileMain: '/dancer/:dancerId',
    routine: '/dancer/:dancerId/routines/:routineId',
    class: '/dancer/:dancerId/classes/:classId',
    allClasses: '/dancer/:dancerId/classes',
    allRoutines: '/dancer/:dancerId/routines',
  },
  purchases: {
    purchasesMain: '/purchases',
  },
};
