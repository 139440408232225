import { useTheme } from 'styled-components';
import { Card } from 'components/Card';
import { Skeleton } from 'components/App/GlobalStyled/skeleton';
import { Typography } from 'components/App/GlobalStyled';
import {
  ContentWrapper,
  PointsWrapper,
  RecapCardSkeletonStyled,
  RecapTitleWrapper,
  TeacherInfoWrapper,
  TextStyled,
} from './styled';

const RecapCardSkeleton = ({ maskText }) => {
  const theme = useTheme();

  return (
    <RecapCardSkeletonStyled>
      <TextStyled>
        <Typography
          fz="18px"
          fw="500"
        >
          {maskText}
        </Typography>
      </TextStyled>

      <Card css={maskText ? 'opacity: 55%;' : ''}>
        <RecapTitleWrapper>
          <Skeleton
            w={56}
            h={22}
            color={theme.gray75}
          />
          <Skeleton
            w={94}
            h={22}
            color={theme.gray75}
          />
          <Skeleton
            w={31}
            h={22}
            color={theme.gray75}
          />
          <Skeleton
            w={47}
            h={22}
            color={theme.gray75}
          />
        </RecapTitleWrapper>

        <ContentWrapper>
          <TeacherInfoWrapper>
            <Skeleton
              w={34}
              h={34}
              borderRadius={100}
              color={theme.gray75}
            />
            <Skeleton
              w={94}
              h={22}
              color={theme.gray75}
            />
          </TeacherInfoWrapper>

          <PointsWrapper>
            <Skeleton
              w={54}
              h={28}
              borderRadius={100}
              color={theme.gray75}
            />
            <Skeleton
              w={54}
              h={28}
              borderRadius={100}
              color={theme.gray75}
            />
          </PointsWrapper>
        </ContentWrapper>

        <Skeleton
          w={86}
          h={16}
          color={theme.gray75}
        />
      </Card>
    </RecapCardSkeletonStyled>
  );
};

export default RecapCardSkeleton;
