import styled, { useTheme } from 'styled-components';
import { Skeleton } from 'components/App/GlobalStyled/skeleton';

const StatsCardSkeletonStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  width: 175px;
  height: 80px;
  background-color: ${({ theme }) => theme.gray25};
  border-radius: 16px;
`;

const StatsCardSkeleton = () => {
  const theme = useTheme();

  return (
    <StatsCardSkeletonStyled>
      <Skeleton
        w={31}
        h={28}
        color={theme.gray75}
      />
      <Skeleton
        w={49}
        h={12}
        color={theme.gray75}
      />
    </StatsCardSkeletonStyled>
  );
};

export default StatsCardSkeleton;
