import { createSlice } from '@reduxjs/toolkit';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  studentsList: [],
  selectedStudent: null,
  studentRating: null,
};

const slice = createSlice({
  name: 'students',
  initialState,
  reducers: {
    setSelectedStudent(state, action) {
      state.selectedStudent = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getStudentsList.fulfilled, (state, action) => {
        state.studentsList = action.payload?.data;
      })
      .addCase(thunks.getStudentRating.fulfilled, (state, action) => {
        state.studentRating = action.payload?.data;
      });
  },
});

const students = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { students };
export default slice.reducer;
