import styled from 'styled-components';

import { Typography } from 'components/App/GlobalStyled';
import RecapCardSkeleton from './RecapCardSkeleton';

const RecapCardsSkeletonStyled = styled.div`
  position: relative;
`;

const TextStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

const RecapCardsWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 16px 0 24px 0;
  opacity: 55%;
`;

const RecapCardsSkeleton = ({ isForEmptyLessons }) => (
  <RecapCardsSkeletonStyled className='animate'>
    {isForEmptyLessons && (
      <TextStyled>
        <Typography
          fz="18px"
          fw="500"
          ta='center'
        >
          Last week recap will be <br />
          displayed here
        </Typography>
      </TextStyled>
    )}

    <RecapCardsWrapper>
      <RecapCardSkeleton />
      <RecapCardSkeleton />
    </RecapCardsWrapper>
  </RecapCardsSkeletonStyled>
);

export default RecapCardsSkeleton;
