import { createSlice } from '@reduxjs/toolkit';
import { thunks } from './thunks';
import { selectors } from './selectors';
import { status } from 'utils/const';

const initialState = {
  scheduleData: [],
  firstPreferenceData: [],
  secondPreferenceData: [],
  lessonForReschedule: {},
  lessonsIdForPayment: null,
  pendingPrivateRequests: [],
  fetchingScheduleStatus: status.IDLE,
};

const slice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    setFirstPreferenceData(state, action) {
      state.firstPreferenceData = action.payload;
    },
    setSecondPreferenceData(state, action) {
      state.secondPreferenceData = action.payload;
    },
    setLessonForReschedule(state, action) {
      state.lessonForReschedule = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getSchedule.pending, (state) => {
        state.fetchingScheduleStatus = status.PENDING;
      })
      .addCase(thunks.getSchedule.fulfilled, (state, action) => {
        state.scheduleData = action.payload?.data;
        state.fetchingScheduleStatus = status.SUCCESS;
      })
      .addCase(thunks.requestLesson.fulfilled, (state, action) => {
        state.lessonsIdForPayment = action.payload?.data;
      })
      .addCase(thunks.getPendingPrivateRequests.fulfilled, (state, action) => {
        state.pendingPrivateRequests = action.payload?.data;
      });
  },
});

const schedule = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { schedule };
export default slice.reducer;
