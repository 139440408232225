import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const getStudentRating = createAsyncThunk(
  'students/getStudentRating',
  async (studentId) => {
    try {
      const url = WEB_API_ROUTES.students.rating.replace(
        ':studentId',
        studentId
      );
      const response = await api.get(url);

      return response.data;
    } catch (err) {
      handleServerErrors(err);
    }
  }
);

const getStudentsList = createAsyncThunk(
  'students/getStudentsList',
  async () => {
    try {
      const response = await api.get(`${WEB_API_ROUTES.students.students}`);

      return response.data;
    } catch (err) {
      handleServerErrors(err);
    }
  }
);

const thunks = {
  getStudentRating,
  getStudentsList,
};

export { thunks };
