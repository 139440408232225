import styled from 'styled-components';

const RPopupStyled = styled.div`
  position: relative;
  top: 50%;
  left: 50%;
  max-width: 420px;
  display: flex;
  flex-direction: column;
  background-color: ${({theme}) => theme.gray25};
  z-index: 100;
  height: min-content;
  border-radius: 16px;
  transform: translate(-50%, -50%);

  ${({css}) => css}
`;

export { RPopupStyled };
