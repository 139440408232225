import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { schedule } from '.';
import { handleServerErrors } from 'utils/server-errors';

const getSchedule = createAsyncThunk(
  'schedule/getSchedule',
  async ({ start, end, studentId }) => {
    try {
      const response = await api.get(`${WEB_API_ROUTES.schedule.schedules}`, {
        params: {
          start,
          end,
          student_id: studentId,
        },
      });
      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getPrivateLessonBookData = createAsyncThunk(
  'schedule/getPrivateData',
  async ({ date, preference = 'both' }, { dispatch }) => {
    try {
      const response = await api.get(`${WEB_API_ROUTES.schedule.private}`, {
        params: {
          date,
        },
      });

      if (preference === 'first' || preference === 'both') {
        dispatch(schedule.actions.setFirstPreferenceData(response.data.data));
      }

      if (preference === 'second' || preference === 'both') {
        dispatch(schedule.actions.setSecondPreferenceData(response.data.data));
      }

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const requestLesson = createAsyncThunk(
  'schedule/requestLesson',
  async ({ preferences, lessonId, studentId }) => {
    try {
      const response = await api.post(
        `${WEB_API_ROUTES.schedule.requestLesson}${
          lessonId ? '/' + lessonId : ''
        } `,
        {
          student_id: studentId,
          preferences,
        }
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getPendingPrivateRequests = createAsyncThunk(
  'schedule/getPendingPrivateRequests',
  async (studentId) => {
    try {
      const response = await api.get(
        `${WEB_API_ROUTES.schedule.pendingRequests}`,
        {
          params: {
            student_id: studentId,
          },
        }
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
    }
  }
);

const thunks = {
  getSchedule,
  getPrivateLessonBookData,
  requestLesson,
  getPendingPrivateRequests,
};

export { thunks };
