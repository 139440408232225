import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const authSendVerification = createAsyncThunk(
  'auth/sendVerification',
  async (loginData) => {
    try {
      const response = await api.post(WEB_API_ROUTES.auth.sendVerification, {
        login: loginData,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const authCheckVerification = createAsyncThunk(
  'auth/checkVerification',
  async ({ loginData, code }) => {
    try {
      const response = await api.post(WEB_API_ROUTES.auth.checkVerification, {
        login: loginData,
        code,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const refreshToken = createAsyncThunk(
  'auth/refreshToken',
  async (_, { getState }) => {
    try {
      const { auth } = getState();
      const response = await api.post(WEB_API_ROUTES.auth.refreshToken, {
        refresh_token: auth.refreshToken,
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const sendLogout = createAsyncThunk('auth/logout', async () => {
  try {
    const response = await api.post(WEB_API_ROUTES.profile.logout);

    return response.data;
  } catch (err) {
    handleServerErrors(err);
    throw err;
  }
});

const thunks = {
  authSendVerification,
  authCheckVerification,
  refreshToken,
  sendLogout
};

export { thunks };
