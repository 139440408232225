import { createSlice } from '@reduxjs/toolkit';
import { selectors } from './selectors';

const initialState = {
  showLoading: false,
};

const slice = createSlice({
  name: 'core',
  initialState,
  reducers: {
    setLoading(state, action) {
      state.showLoading = action.payload;
    },
  },
  extraReducers: () => {},
});

const core = {
  actions: slice.actions,
  selectors,
};

export { core };
export default slice.reducer;
