import { createGlobalStyle, css } from 'styled-components';
import { colors } from 'utils/colors';
import { device } from 'utils/device';

export const GlobalStyles = createGlobalStyle`
  ${({ theme }) => css`
    @font-face {
      font-family: 'Inter';
      src: url('/fonts/Inter-Black') format('ttf'),
        url('/fonts/Inter-Bold') format('ttf'),
        url('/fonts/Inter-ExtraBold') format('ttf'),
        url('/fonts/Inter-ExtraLight') format('ttf'),
        url('/fonts/Inter-Light') format('ttf'),
        url('/fonts/Inter-Medium') format('ttf'),
        url('/fonts/Inter-Regular') format('ttf'),
        url('/fonts/Inter-SemiBold') format('ttf'),
        url('/fonts/Inter-Thin') format('ttf');
      font-weight: 300;
      font-style: normal;
    }

    * {
      &::-webkit-scrollbar {
        height: 4px;
        width: 4px;
      }

      &::-webkit-scrollbar-track {
        background: ${theme.white};
        border-radius: 4px;
      }

      &::-webkit-scrollbar-thumb {
        background: ${theme.osloGrey};
        border-radius: 4px;
      }
    }

    body {
      max-width: 420px;
      margin: 0 auto;
      font-family: 'Inter', sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 145%;
      color: ${theme.maastrichtBlue};
      background-color: ${theme.gray50};

      .animate {
        opacity: 0;
      }

      .no-inner-scroll {
        overflow: hidden;
      }

      .Toastify__toast-container {
        top: 90px;
        right: 16px;
        padding: 0 16px;
        word-break: break-word;

        .customIcon {
          background-image: url('react/src/assets/img/SVG.js');
          width: 20px;
          height: 20px;
        }

        .Toastify__toast {
          border-radius: 12px;
          padding: 0;
          min-height: auto;
          font-size: 16px;
          font-weight: 500;
          line-height: normal;
          font-family: 'Inter', sans-serif;
          color: ${theme.gray950};
          border: 0.5px solid ${theme.green500};
          background-color: ${theme.green25};

          &--error {
            border: 0.5px solid ${theme.red500};
            background-color: ${theme.red100};
          }

          &-body {
            padding: 14px 18px;
            margin: 0;
          }

          &-icon {
            width: 24px;
          }
        }
      }

      .react-tel-input {
        .country-list {
          padding: 6px 0;
          background-color: ${theme.white};
          border: 1px solid ${theme.gainsboro};
          box-shadow: 0 4px 6px 1px rgba(74, 80, 87, 0.13);
          border-radius: 6px;
          margin: 4px 0 4px -1px;

          &::-webkit-scrollbar {
            height: 4px;
            width: 4px;
          }

          &::-webkit-scrollbar-track {
            background: transparent;
            border-radius: 4px;
          }

          &::-webkit-scrollbar-thumb {
            background: ${theme.romanSilver};
            border-radius: 4px;
          }

          .country {
            &.highlight,
            &:hover {
              background-color: ${theme.honeydew};
            }
          }
        }
      }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'Roboto', sans-serif;
    }

    h1 {
      font-size: 42px;
      font-weight: 500;
      line-height: 130%;
      letter-spacing: -0.1px;
    }

    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: 0;
    }

    h3 {
      font-size: 22px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.5px;
    }

    h4 {
      font-size: 20px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.5px;
    }

    h5 {
      font-size: 16px;
      font-weight: 400;
      line-height: 140%;
      letter-spacing: 0.3px;
    }
  `}
`;
