import { SEND_SQUARE_SVG, TOAST_ERROR_SVG } from 'components/ui/_shared/SVG';
import { toast } from 'react-toastify';
import { colors } from './colors';

const error = (msg) => {
  toast.error(msg, {
    icon: () => <TOAST_ERROR_SVG />,
  });
};

const success = (msg) => {
  // const theme = useTheme();

  toast.success(msg, {
    icon: () => <SEND_SQUARE_SVG color="#42CD47" />,
  });
};

export const showToast = { error, success };
