import { useTheme } from 'styled-components';

import { Card } from 'components/Card';
import { Skeleton } from 'components/App/GlobalStyled/skeleton';
import { Typography } from 'components/App/GlobalStyled';
import {
  LessonCardSkeletonStyled,
  LessonCardTeacherInfoWrapper,
  LessonCardTimeBlockWrapper,
  LessonCardTimeWrapper,
  LessonCardTitleWrapper,
  TextStyled,
} from './styled';

const LessonCardSkeleton = ({ maskText }) => {
  const theme = useTheme();

  return (
    <LessonCardSkeletonStyled className='animate'>
      <TextStyled>
        <Typography
          fz="18px"
          fw="500"
        >
          {maskText}
        </Typography>
      </TextStyled>

      <Card css={maskText ? 'opacity: 55%;' : ''}>
        <Skeleton
          w={68}
          h={11}
          color={theme.gray75}
          m="0 0 10px 0"
        />

        <LessonCardTitleWrapper>
          <Skeleton
            w={68}
            h={28}
            color={theme.gray75}
          />

          <Skeleton
            w={106}
            h={28}
            color={theme.gray75}
          />
        </LessonCardTitleWrapper>

        <LessonCardTimeBlockWrapper>
          <LessonCardTimeWrapper isStart>
            <Skeleton
              w={68}
              h={21}
              color={theme.gray75}
            />
            <Skeleton
              w={49}
              h={17}
              color={theme.gray75}
            />
          </LessonCardTimeWrapper>

          <Skeleton
            w={60}
            h={28}
            borderRadius={100}
            color={theme.gray75}
          />

          <LessonCardTimeWrapper>
            <Skeleton
              w={68}
              h={21}
              color={theme.gray75}
            />
            <Skeleton
              w={49}
              h={17}
              color={theme.gray75}
            />
          </LessonCardTimeWrapper>
        </LessonCardTimeBlockWrapper>

        <LessonCardTeacherInfoWrapper>
          <Skeleton
            w={34}
            h={34}
            borderRadius={100}
            color={theme.gray75}
          />
          <Skeleton
            w={49}
            h={22}
            color={theme.gray75}
          />
          <Skeleton
            w={78}
            h={22}
            color={theme.gray75}
          />
        </LessonCardTeacherInfoWrapper>
      </Card>
    </LessonCardSkeletonStyled>
  );
};

export default LessonCardSkeleton;
