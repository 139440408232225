import styled, { css } from 'styled-components';
import { colors } from 'utils/colors';

const Button = styled.button`
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'auto')};
  height: ${({ height }) => `${height}px`};
  padding: 0 18px;
  font-size: 14px;
  font-weight: 500;
  border-width: 1px;
  border-style: solid;
  border-radius: 100px;
  border-color: transparent;
  overflow: hidden;
  transition: 0.15s linear;
  cursor: pointer;

  ${({ variant, disabled }) => {
    switch (variant) {
      case 'outline':
        return css`
          color: ${disabled ? colors.gray100 : colors.gray950};
          background-color: ${colors.gray50};

          &:hover {
            background-color: ${disabled ? colors.gray50 : colors.gray75};
          }

          &:active {
            background-color: ${disabled ? colors.gray50 : colors.gray90};
          }
        `;
      case 'outlineBordered':
        return css`
          border: 0.5px solid ${colors.gray100};
          color: ${colors.gray950};
          border-radius: 16px;
          background-color: transparent;
          padding: 16px 16px;
          opacity: ${disabled ? 0.3 : 1} !important;

          &:hover {
            background-color: ${disabled ? 'transparent' : colors.gray75};
          }

          &:active {
            background-color: ${disabled ? 'transparent' : colors.gray90};
          }
        `;
      case 'small':
        return css`
          border-radius: 16px;

          color: ${colors.gray25};
          background-color: ${colors.brand500};

          &:hover {
            background-color: ${colors.brand600};
          }

          &:active {
            background-color: ${colors.brand700};
          }
        `;
      case 'smallOutlineBordered':
        return css`
          border: 1px solid ${colors.gray950};
          color: ${colors.gray950};
          border-radius: 16px;
          background-color: transparent;
          padding: 0 16px;

          &:hover {
            background-color: ${colors.gray75};
          }

          &:active {
            background-color: ${colors.gray90};
          }
        `;
      default:
        return css`
          color: ${colors.gray25};
          background-color: ${colors.brand500};
          opacity: ${disabled ? 0.3 : 1};

          &:hover {
            background-color: ${colors.brand600};
          }

          &:active {
            background-color: ${colors.brand700};
          }
        `;
    }
  }}

  ${({ css }) => css}
`;

const ButtonChildren = styled.span`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;

const PrependIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 5px;

  ${({ disabled, theme }) =>
    disabled &&
    css`
      & > svg {
        fill: ${theme.gainsboro};
      }
    `}
`;

const LoadingStyled = styled.img`
  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  animation: rotate 2s linear infinite;
`;

export { Button, ButtonChildren, PrependIcon, LoadingStyled };
