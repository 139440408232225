import PropTypes from 'prop-types';

import { RMask } from 'components/RMask';
import { RPopupStyled } from './styled';

RPopup.propTypes = {
  children: PropTypes.any,
};

function RPopup({ css, children }) {
  return (
    <RMask color="rgba(9, 7, 11, 0.32)">
      <RPopupStyled css={css}>{children}</RPopupStyled>
    </RMask>
  );
}

export { RPopup };
