import styled from 'styled-components';

const ScheduleSkeletonStyled = styled.div`
  display: flex;
  flex-direction: column;
  margin: 100px 0;
  padding: 0 16px;
`;

const WeekAndDatePickerSkeleton = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 16px 0;
`;

const StatsCardsWrapper = styled.div`
  display: flex;
  padding: 16px 0;
  gap: 11px;
`;

export { ScheduleSkeletonStyled, WeekAndDatePickerSkeleton, StatsCardsWrapper };
