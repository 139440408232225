import { useTheme } from 'styled-components';
import { useSelector } from 'react-redux';
import { useLocation, matchPath } from 'react-router-dom';

import { Typography } from 'components/App/GlobalStyled';
import { APP_ROUTES } from 'router/appRoutes';
import { profile } from 'store/profile';
import {
  ARCHIVE_SVG,
  CALENDAR_SVG,
  GALLERY_SVG,
  PROFILE_SVG,
} from 'components/ui/_shared/SVG';
import {
  BtnStyled,
  BtnsWrapperStyled,
  FooterNavigationStyled,
  LinkStyled,
} from './styled';

const navBnts = [
  {
    SVG: CALENDAR_SVG,
    name: 'Schedule',
    route: APP_ROUTES.schedule.scheduleMain,
  },
  { SVG: GALLERY_SVG, name: 'Media', route: APP_ROUTES.media.mediaMain },
  {
    SVG: ARCHIVE_SVG,
    name: 'Purchases',
    route: APP_ROUTES.purchases.purchasesMain,
  },
  { SVG: PROFILE_SVG, name: 'Dancer', route: APP_ROUTES.profile.profileMain },
];

const FooterNavigation = () => {
  const location = useLocation();
  const selectedStudentId = useSelector(profile.selectors.selectedStudentId);
  const theme = useTheme();
  const showFooter = navBnts.some(({ route }) =>
    matchPath(location.pathname, { path: route, exact: true })
  );

  return showFooter ? (
    <FooterNavigationStyled>
      <BtnsWrapperStyled>
        {navBnts.map(({ name, route, SVG }) => (
          <BtnStyled key={name}>
            <LinkStyled
              to={
                route === APP_ROUTES.profile.profileMain
                  ? APP_ROUTES.profile.profileMain.replace(
                      ':dancerId',
                      selectedStudentId
                    )
                  : route
              }
            >
              <SVG
                color={
                  matchPath(location.pathname, { path: route, exact: true })
                    ? theme.brand500
                    : theme.gray400
                }
              />

              <Typography
                fz="13"
                fw="400"
                td="none"
                color={
                  matchPath(location.pathname, { path: route, exact: true })
                    ? theme.brand500
                    : theme.gray400
                }
              >
                {name}
              </Typography>
            </LinkStyled>
          </BtnStyled>
        ))}
      </BtnsWrapperStyled>
    </FooterNavigationStyled>
  ) : (
    <></>
  );
};

export default FooterNavigation;
