import styled, { css } from 'styled-components';

const Skeleton = styled.div`
  ${({ w = 0, h = 0, m = 0, color, borderRadius = 5, theme }) => css`
    width: 100%;
    width: ${w}px;
    height: ${h}px;
    margin: ${m};
    background-color: ${color || theme.gray25};
    border-radius: ${borderRadius}px;
  `}
`;

export { Skeleton };
