import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const getRoutinesList = createAsyncThunk(
  'routines/getRoutinesList',
  async (studentId) => {
    try {
      const response = await api.get(WEB_API_ROUTES.routine.routineList, {
        params: {
          student_id: studentId,
        },
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
    }
  }
);

const getRoutine = createAsyncThunk(
  'routines/getRoutine',
  async ({ routineId, selectedStudentId }) => {
    try {
      const response = await api.get(WEB_API_ROUTES.routine.currentRoutine, {
        params: {
          id: routineId,
          student_id: selectedStudentId,
        },
      });

      return response.data;
    } catch (err) {
      handleServerErrors(err);
    }
  }
);

const thunks = {
  getRoutinesList,
  getRoutine,
};

export { thunks };
