import { Link } from 'react-router-dom';
import styled from 'styled-components';

const FooterNavigationStyled = styled.nav`
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  height: 90px;
  width: 100%;
  max-width: 420px;
  background-color: ${({theme}) => theme.gray50};
  border-top: 0.5px solid ${({theme}) => theme.gray100};
  z-index: 5;
`;

const BtnsWrapperStyled = styled.ul`
  display: flex;
  justify-content: center;
  padding: 12px 0;
`;

const BtnStyled = styled.li`
  display: flex;
  justify-content: center;
  list-style: none;
  gap: 4px;
  flex: 1;
`;

const LinkStyled = styled(Link)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;

  &:hover {
    text-decoration: none;
  }
`;

export { FooterNavigationStyled, BtnsWrapperStyled, LinkStyled, BtnStyled };
