import { createSlice } from '@reduxjs/toolkit';
import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  routinesList: [],
  currentRoutine: null,
};

const slice = createSlice({
  name: 'routines',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getRoutinesList.fulfilled, (state, action) => {
        state.routinesList = action.payload?.data;
      })
      .addCase(thunks.getRoutine.fulfilled, (state, action) => {
        state.currentRoutine = action.payload?.data;
      });
  },
});

const routines = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { routines };
export default slice.reducer;
