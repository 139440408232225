import { useTheme } from 'styled-components';

import { Card } from 'components/Card';
import { LessonRequestCardSceletonStyled, TimeSceletonStyled } from './styled';
import { Skeleton } from 'components/App/GlobalStyled/skeleton';

export const LessonRequestCardSceleton = () => {
  const theme = useTheme();

  return (
    <Card>
      <LessonRequestCardSceletonStyled>
        <Skeleton
          w={185}
          h={21}
          color={theme.gray75}
        />

        <TimeSceletonStyled>
          <Skeleton
            w={96}
            h={21}
            color={theme.gray75}
          />
          <Skeleton
            w={96}
            h={21}
            color={theme.gray75}
          />
        </TimeSceletonStyled>
      </LessonRequestCardSceletonStyled>
    </Card>
  );
};
