import { useTheme } from 'styled-components';

import { PLUS_SVG } from 'components/ui/_shared/SVG';
import { Header } from 'components/Header';
import { RButton } from 'components/ui/RButton';
import { Typography } from 'components/App/GlobalStyled';
import { Skeleton } from 'components/App/GlobalStyled/skeleton';
import StatsCardSkeleton from './StatsCardSkeleton';
import LessonCardsSkeleton from './LessonCardsSkeleton';
import RecapCardsSkeleton from './RecapCardsSkeleton';
import {
  ScheduleSkeletonStyled,
  StatsCardsWrapper,
  WeekAndDatePickerSkeleton,
} from './styled';
import { LessonRequestCardsSceleton } from './LessonRequestCardsSceleton';

const ScheduleSkeleton = () => {
  const theme = useTheme();

  return (
    <>
      <Header>
        <RButton
          variant="small"
          prependIcon={
            <PLUS_SVG
              size="15px"
              color="#FFFFFF"
            />
          }
        >
          New lesson
        </RButton>
      </Header>

      <ScheduleSkeletonStyled>
        <Typography
          fz="20px"
          fw="600"
          p="16px 0"
        >
          Schedule
        </Typography>
        <WeekAndDatePickerSkeleton>
          <Skeleton
            w={210}
            h={32}
            borderRadius={6}
          />
          <Skeleton
            w={32}
            h={32}
            borderRadius={6}
          />
        </WeekAndDatePickerSkeleton>

        <LessonCardsSkeleton />
        <RButton
          variant="outlineBordered"
          fullWidth
          height={60}
        >
          <Skeleton
            w={180}
            h={21}
            color={theme.gray75}
          />
        </RButton>
        <Typography
          fz="26px"
          fw="600"
          p="24px 0 16px 0"
        >
          Pending Private Request
        </Typography>
        <LessonRequestCardsSceleton />
        <Typography
          fz="26px"
          fw="600"
          p="24px 0 16px 0"
        >
          Last week recap
        </Typography>
        <StatsCardsWrapper>
          <StatsCardSkeleton />
          <StatsCardSkeleton />
        </StatsCardsWrapper>
        <RecapCardsSkeleton />
        <RButton
          variant="outlineBordered"
          fullWidth
          height={60}
        >
          <Skeleton
            w={180}
            h={21}
            color={theme.gray75}
          />
        </RButton>
      </ScheduleSkeletonStyled>
    </>
  );
};

export default ScheduleSkeleton;
