import { LineLoaderWrapperStyled, LineStyled } from './styled';
//TODO remove animation if there is no loader to open it from start every time
export const LineLoader = () => (
  <LineLoaderWrapperStyled>
    <LineStyled />
    <LineStyled />
    <LineStyled />
    <LineStyled />
  </LineLoaderWrapperStyled>
);
