import { createAsyncThunk } from '@reduxjs/toolkit';

import api from 'api';
import { WEB_API_ROUTES } from 'api/api-routes';
import { handleServerErrors } from 'utils/server-errors';

const getProfile = createAsyncThunk('profile/getProfile', async () => {
  try {
    const response = await api.get(`${WEB_API_ROUTES.profile.profile}`);

    return response.data;
  } catch (err) {
    throw err;
  }
});

const changeProfileFirstLogin = createAsyncThunk(
  'profile/changeProfileFirstLogin',
  async () => {
    try {
      const response = await api.post(
        `${WEB_API_ROUTES.profile.changeFirstLogin}`
      );

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const getFirstStudent = createAsyncThunk(
  'profile/getFirstStudent',
  async () => {
    try {
      const response = await api.get(`${WEB_API_ROUTES.students.firstStudent}`);

      return response.data;
    } catch (err) {
      handleServerErrors(err);
      throw err;
    }
  }
);

const thunks = {
  getProfile,
  changeProfileFirstLogin,
  getFirstStudent
};

export { thunks };
