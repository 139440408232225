import styled from 'styled-components';

const RecapCardSkeletonStyled = styled.div`
  position: relative;
`;

const RecapTitleWrapper = styled.div`
  display: flex;
  gap: 5px;
  margin-bottom: 12px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
`;

const TeacherInfoWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
`;

const PointsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

const TextStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export {
  RecapCardSkeletonStyled,
  RecapTitleWrapper,
  ContentWrapper,
  TeacherInfoWrapper,
  PointsWrapper,
  TextStyled
};
