import { lazy, Suspense } from 'react';
import { Switch, Route } from 'react-router-dom';
import { useSelector } from 'react-redux';

import { APP_ROUTES } from 'router/appRoutes';
import ScheduleSkeleton from 'components/skeletons/ScheduleSkeleton';
import FooterNavigation from 'components/FooterNavigation';
import { NotFoundPage } from 'pages/NotFound';
import { useScrollToTop } from 'hooks/useScrollToTop';
import { RLoadingOverlay } from 'components/RLoadingOverlay';
import { core } from 'store/core';

const AuthLayout = lazy(() => import('layouts/AuthLayout'));
const ScheduleLayout = lazy(() => import('layouts/ScheduleLayout'));
const ProfileLayout = lazy(() => import('layouts/ProfileLayout'));
const MediaLayout = lazy(() => import('layouts/MediaLayout'));
const PaymentLayout = lazy(() => import('layouts/PaymentLayout'));
const PurchasesLayout = lazy(() => import('layouts/PurchasesLayout'));

function App() {
  const showLoading = useSelector(core.selectors.showLoading);
  useScrollToTop();

  return (
    <Suspense>
      <RLoadingOverlay isVisible={showLoading} />

      <Switch>
        <Route
          path={APP_ROUTES.media.mediaMain}
          component={MediaLayout}
        />
        <Route
          path={APP_ROUTES.profile.profileMain}
          component={ProfileLayout}
        />
        <Route
          path={APP_ROUTES.purchases.purchasesMain}
          component={PurchasesLayout}
        />
        <Route
          path={APP_ROUTES.payment.payment}
          component={PaymentLayout}
        />
        <Route path={APP_ROUTES.schedule.scheduleMain}>
          <Suspense fallback={<ScheduleSkeleton />}>
            <ScheduleLayout />
          </Suspense>
        </Route>
        <Route
          path={APP_ROUTES.auth}
          component={AuthLayout}
        />
        <Route
          path="*"
          component={NotFoundPage}
        />
      </Switch>

      <FooterNavigation />
    </Suspense>
  );
}

export default App;
