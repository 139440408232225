import styled, { keyframes } from 'styled-components';
import { colors } from 'utils/colors';

const LineLoaderWrapperStyled = styled.div`
  position: fixed;
  top: 5px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  gap: 6px;
  width: 100%;
  max-width: 420px;
  overflow: hidden;
  border-radius: 100px;
`;

const loadingAnimation = keyframes`
  0% {
    transform: translateX(-404.5%);
  }
  15% {
    transform: translateX(-304.5%);
  }
  25% {
    transform: translateX(-304.5%);
    margin-left: 0;
  }
  26% {
    margin-left: 20px;
  }
  35% {
    transform: translateX(-205.8%);
    margin-left: 0;
  }
  45% {
    transform: translateX(-205.8%);
    margin-left: 0;
  }
  46% {
    margin-left: 20px;
  }
  55% {
    transform: translateX(-104.3%);
    margin-left: 0;
  }
  65% {
    transform: translateX(-104.3%);
    margin-left: 0px;
  }
  66% {
    margin-left: 20px;
  }
  75% {
    transform: translateX(-2.8%);
    margin-left: 0;
  }
  85% {
    transform: translateX(-2.8%);
  }
  100% {
    transform: translateX(100%);
  }
`;

const LineStyled = styled.div`
  min-width: 420px;
  height: 6px;
  background-color: ${colors.gray25};
  border-radius: 100px;
  z-index: 1;
  animation: ${loadingAnimation} 4s infinite;
  animation-timing-function: ease-in-out;
`;

export { LineLoaderWrapperStyled, LineStyled };
