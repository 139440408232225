import styled from 'styled-components';

const LessonRequestCardSceletonStyled = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const TimeSceletonStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export { LessonRequestCardSceletonStyled, TimeSceletonStyled };
