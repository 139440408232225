import { createSlice } from '@reduxjs/toolkit';

import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  clientSecret: null,
  transactionsList: [],
};

const slice = createSlice({
  name: 'payment',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getClientSecret.fulfilled, (state, action) => {
        state.clientSecret = action.payload?.data;
      })
      .addCase(thunks.getTransactionsList.fulfilled, (state, action) => {
        state.transactionsList = action.payload?.data;
      });
  },
});

const payment = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { payment };
export default slice.reducer;
