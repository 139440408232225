import styled from 'styled-components';

const LessonCardSkeletonStyled = styled.div`
  position: relative;
`;

const LessonCardTitleWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 11px;
`;

const LessonCardTimeBlockWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LessonCardTimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
  align-items: ${({ isStart }) => (isStart ? 'start' : 'end')};
  margin-bottom: 8px;
`;

const LessonCardTeacherInfoWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;

const TextStyled = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export {
  LessonCardSkeletonStyled,
  LessonCardTitleWrapper,
  LessonCardTimeBlockWrapper,
  LessonCardTimeWrapper,
  LessonCardTeacherInfoWrapper,
  TextStyled,
};
