import { createSlice } from '@reduxjs/toolkit';

import { thunks } from './thunks';
import { selectors } from './selectors';

const initialState = {
  classRoomMedia: null,
  routinesMedia: null,
  mediaMain: null,
  currentMedia: null,
  isPhotoTabSelected: true,
};

const slice = createSlice({
  name: 'media',
  initialState,
  reducers: {
    setCurrentMedia: (state, action) => {
      state.currentMedia = action.payload;
    },
    setIsPhotoTabSelected: (state, action) => {
      state.isPhotoTabSelected = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(thunks.getClassMedia.fulfilled, (state, action) => {
        state.classRoomMedia = action.payload?.data;
      })
      .addCase(thunks.getRoutinesMedia.fulfilled, (state, action) => {
        state.routinesMedia = action.payload?.data;
      })
      .addCase(thunks.getMedia.fulfilled, (state, action) => {
        state.mediaMain = action.payload?.data;
      })
      .addCase(thunks.getMediaById.fulfilled, (state, action) => {
        state.currentMedia = action.payload?.data;
      });
  },
});

const media = {
  actions: slice.actions,
  thunks,
  selectors,
};

export { media };
export default slice.reducer;
