import styled from 'styled-components';
import { LessonRequestCardSceleton } from './LessonRequestCardSceleton';
import { Typography } from 'components/App/GlobalStyled';

const PendingRequestsWrapperStyled = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 16px;
`;

const LessonCardsSkeletonStyled = styled.div`
  position: relative;
`;

const CardsWrapperStyled = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 24px;
  opacity: 55%;
`;

const TextStyled = styled.div`
  position: absolute;
  top: 48%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  z-index: 1;
`;

export const LessonRequestCardsSceleton = ({ isForEmptyLessons }) => (
  <LessonCardsSkeletonStyled>
    {isForEmptyLessons && (
      <TextStyled>
        <Typography
          fz="18px"
          fw="500"
        >
          Requests will be displayed here
        </Typography>
      </TextStyled>
    )}

    <CardsWrapperStyled>
      <LessonRequestCardSceleton />
      <LessonRequestCardSceleton />
    </CardsWrapperStyled>
  </LessonCardsSkeletonStyled>
);
