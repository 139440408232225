import styled from 'styled-components';
import { CHEVRON_SVG } from 'components/ui/_shared/SVG';
import { Typography } from 'components/App/GlobalStyled';

const HeaderStyled = styled.div`
  position: fixed;
  top: 0;
  height: 100px;
  width: 100%;
  max-width: 420px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 20;
  display: flex;
  justify-content: ${({ moveToRight }) =>
    moveToRight ? 'space-between' : 'left'};
  align-items: center;
  padding: 0 16px;
  font-size: 16px;
  background-color: ${({ theme }) => theme.gray50};
  border-radius: 0 0 16px 16px;
  color: white;
`;

const LogoWrapperStyled = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

export const Header = ({
  showBack = false,
  onBackClick,
  moveContentToRight,
  children,
}) => (
  <HeaderStyled moveToRight={!showBack || moveContentToRight}>
    {showBack ? (
      <CHEVRON_SVG
        onClick={onBackClick}
        styles={{ marginRight: showBack ? '4px' : '' }}
      />
    ) : (
      <LogoWrapperStyled>
        <Typography
          fw="800"
          fz="20px"
        >
          db studios
        </Typography>
      </LogoWrapperStyled>
    )}

    {children}
  </HeaderStyled>
);
