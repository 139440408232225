export const WEB_API_ROUTES = {
  auth: {
    sendVerification: '/auth/send-verification',
    checkVerification: '/auth/check-verification',
    refreshToken: '/auth/refresh',
  },
  schedule: {
    schedules: '/schedules',
    private: '/schedules/private-lesson',
    requestLesson: '/schedules/create-private-lesson-requests',
    pendingRequests: '/schedules/private-lesson-requests',
  },
  profile: {
    profile: '/profile',
    logout: '/profile/logout',
    changeFirstLogin: '/profile/change-first-login',
  },
  students: {
    students: '/students',
    firstStudent: '/students/first',
    rating: '/students/:studentId/dancer-ratings',
  },
  class: {
    classList: '/class/list',
    classroom: '/class/classroom',
    schedules: '/class/schedules',
  },
  routine: {
    routineList: '/routine',
    currentRoutine: '/routine/routine',
  },
  payment: {
    payment: '/payment/stripe/create-private-intent',
    addPaymentMethod: '/payment/stripe/add-payment-method',
    pay: '/payment/stripe/pay',
    transactionsList: '/payment/stripe/transactions',
    checkout: '/payment/stripe/checkout',
  },
  media: {
    classroom: '/media/classroom',
    routines: '/media/routine',
    mediaMain: '/media/student',
    mediaById: '/media/get',
  },
};
